import React from 'react';
import { TextInput } from '~/components/input';

interface Props {
	value: string;
	onChange: (e) => void;
	disabled?: boolean;
}

const PasskeyInput: React.FC<Props> = (props) => {
	const keyMask = new Array(13).fill(/[1-9a-zA-Z]/, 0, 13);
	return (
		<TextInput
			{...props}
			autoFocus
			name="passkey"
			placeholder="Enter passkey here"
			guide={false}
			label="13-Digit Passkey"
			mask={keyMask}
			pipe={(e) => e.toUpperCase()}
		/>
	);
};

export default PasskeyInput;
