import validate from 'validate.js';

export const cardValidation = {
	cardHolder: {
		presence: true,
		length: {
			minimum: 1
		}
	},
	cardNumber: {
		presence: true,
		length: {
			minimum: 15
		}
	},
	CVV: {
		presence: true,
		length: {
			minimum: 3,
			message: '^CVV must be at least 3 digits'
		}
	}
};

export const addressDetailsValidation = {
	street1: {
		presence: true,
		length: {
			minimum: 2
		}
	},
	zip: {
		presence: true,
		length: {
			minimum: 5,
			maximum: 5
		},
		numericality: true
	},
	city: {
		presence: true,
		length: {
			minimum: 2
		}
	},
	state: {
		presence: true
	}
};

export const shippingAddressDetailsValidation = {
	...addressDetailsValidation
};

export const emailValidation = {
	email: {
		presence: true,
		email: true
	},
	email_confirm: {
		presence: true,
		email: true
	}
};

const lowercaseCharacters = /[a-z]/;
const uppercaseCharacters = /[A-Z]/;
const numericCharacters = /\d/;
const nonAlphanumericCharacters = /[^A-z\d]/;

validate.validators.passwordCharacterSets = function (value, options, key, attributes) {
	if (!value) return;

	let setsMatched = 0;

	if (value.match(lowercaseCharacters)) {
		setsMatched++;
	}

	if (value.match(uppercaseCharacters)) {
		setsMatched++;
	}

	if (value.match(numericCharacters)) {
		setsMatched++;
	}

	if (value.match(nonAlphanumericCharacters)) {
		setsMatched++;
	}

	if (setsMatched < 3) {
		return 'Password must contain characters from multiple sets';
	}
};

// Currently does not enforce inclusion of characters
// from certain character sets. If we ever enable this,
// then we should set `passwordCharacterSets: true` in
// the validation configuration below for both `password`
// and `password_confirm`.

export const passwordValidation = {
	password: {
		presence: true,
		length: {
			minimum: 8
		}
	},
	password_confirm: {
		presence: true,
		length: {
			minimum: 8
		}
	}
};
